import { Component, OnInit, Host, HostBinding, OnDestroy } from '@angular/core';
import { LoginService } from '../login.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, query, group, stagger } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  url = null;
  loading = false;

  constructor(
    private loginService: LoginService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.loginService.redirect);
  }

  ngOnDestroy() {
    this.loading = false;
  }

  logout() {
    this.loading = true;
    this.loginService.logged = false;
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
