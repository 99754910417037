import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoginService } from './login.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private login: LoginService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.login.logged) {
      return of(this.login.logged);
    } else {
      if (!!this.login.token) {
        console.log(this.login.token);
        return this.login
          .login({ login: null, password: null, sesame_token: this.login.token }, true)
          .pipe(
            map(res => {
              console.log(res.status !== 500, res.status);
              return res.status !== 500;
            })
          );
      } else {
        this.router.navigate(['/login']);
      }
    }
    return of(false);
  }
}
